html,
body {
  position: absolute;
  height: 100%;
  max-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
}
